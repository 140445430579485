h1,
h2,
h3,
h4 {
    font-family: $yanone !important;
    font-weight: 700 !important;
}

h1 {
    @media screen and (min-width: $screen-md) {
        font-size: 2.5em !important;
    }
}

h2 {
    @media screen and (min-width: $screen-md) {
        font-size: 2em !important;
    }
}

h3 {
    @media screen and (min-width: $screen-md) {
        font-size: 1.8em !important;
    }
}

h4 {
    font-size: 1.3em !important;
    @media screen and (min-width: $screen-md) {
        font-size: 1.5em !important;
    }
}

p {
    margin: 15px 0;
}

.arborhome {
    h1 {
        font-family: $yanone;
        font-weight: 700 !important;
        @media screen and (min-width: $screen-md) {
            font-size: 5em !important;
        }
    }

    h2 {
        font-family: $yanone;
        font-weight: 700 !important;
        @media screen and (min-width: $screen-md) {
            font-size: 3em !important;
        }
    }
}

b,
strong {
    font-weight: 700;
}
