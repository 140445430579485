.arbor-padding {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ms-search-result-container__refiner-section button.ms-refine-submenu__toggle_collapsed,
.ms-search-result-container__refiner-section .ms-refine-submenu__toggle_expanded {
    font-family: $jost;
}

.ms-search-result-container__Sort-by-category {
    font-family: $jost;
    padding: 10px;
}

.ms-search-result-container__Sort-by-category .msc-l-dropdown__label {
    margin: 0;
}

.ms-search-result-container__category-nav-section {
    float: right;
    margin-top: 0;
}

.ms-buybox__dropdown {
    display: flex;
    flex-wrap: wrap;
    > select,
    > label {
        width: 100%;
    }
    > label {
        order: 1;
    }
    > select {
        order: 2;
    }

    label {
        opacity: 0;
        transition: opacity 0.3s ease;
    }
}

div[data-exp-event-id='DefaultPdp.PageView'] {
    .ms-buybox__dropdown {
        label {
            opacity: 1;
        }
        select[aria-label='Style'] ~ label {
            > div {
                display: none;
            }
        }
        select[aria-label='Style'] ~ label::after {
            content: 'Quality';
        }
    }
}

.ms-quickView__button {
    border: 1px solid $arborBrown;
}
.ms-quickView__button:hover,
.ms-quickView__button:focus {
    background-color: $arborLtBrown;
}

.ms-quickView__product-title {
    line-height: normal;
    font-size: 1.5em !important;
}

.ms-quickView__body .msc-price__actual,
.ms-quickView__configure {
    font-family: $jost;
    font-size: inherit;
}

.ms-quickView__dropdown-quantity-label,
.ms-quickView__body .msc-dropdown__select {
    font-family: $jost;
}

.ms-quickView__body .msc-dropdown__select {
    font-size: inherit;
    border: 1px solid #000;
}

.ms-quickView__dropdown {
    margin-top: 10px;
}

.ms-quick-order__content-form-main__dropdown-quantity-label {
    margin-bottom: 0;
}

.ms-refine-submenu.Rating {
    display: none !important;
}

.ms-refine-submenu.ColorStd {
    > .msc-btn {
        visibility: hidden;
    }
    > .msc-btn::before {
        content: 'Color';
        visibility: visible;
    }
    > .msc-btn::after {
        visibility: visible;
    }
}

.ms-refine-submenu.SizeStd {
    > .msc-btn {
        visibility: hidden;
    }
    > .msc-btn::before {
        content: 'Size';
        visibility: visible;
    }
    > .msc-btn::after {
        visibility: visible;
    }
}

.ms-refine-submenu.StyleStd {
    > .msc-btn {
        visibility: hidden;
    }
    > .msc-btn::before {
        content: 'Quality';
        visibility: visible;
    }
    > .msc-btn::after {
        visibility: visible;
    }
}
