// I do not know why... I'm confused as well as to why this is display: none; It makes no sense.

#renderPage {
    > header {
        position: fixed;
        z-index: 100;
        width: 100%;
    }
}

#main {
    padding-top: 76px;
}

.ms-header .ms-header__account-info {
    display: block;
}

.ms-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    background: #fff;
    border: none;
    border-bottom: 1px solid #e4e4e4;
    max-height: 76px;
}

.ms-header__container {
    padding-bottom: 0;
}

.arborb2b-logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 13.5px 0;
    a {
        max-width: 160px;
        width: 100%;
    }
    svg {
        width: 160px;
        height: 34px;
    }
    @media screen and (min-width: $msv-breakpoint-l) {
        max-height: 75px;
        flex: 0 0 180px;
        display: block;
        padding: 20px 20px 20px 0;
    }

    .ms-header__logo {
        flex: 0 0 160px;
    }
}

.arborb2b-other {
    display: none;
    @media screen and (min-width: $msv-breakpoint-l) {
        display: flex;
        max-height: 75px;
    }
}

.ms-header .ms-header__preferred-store-btn,
.ms-header .ms-search__icon,
.ms-header .ms-header__signin-button,
.ms-header .msc-wishlist-icon,
.ms-header .msc-cart-icon,
.ms-header .ms-header__profile-button {
    font-family: $jost;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.9em;
}

.ms-header .ms-header__account-info .ms-header__signin-button {
    font-weight: 400;
    font-size: 0.8em;
    @media screen and (min-width: $msv-breakpoint-l) {
        background-color: #fff;
    }
}

.ms-search__icon {
    width: auto;
}
.ms-search__icon-text {
    display: none;
}

.ms-header .ms-header__collapsible-hamburger {
    margin-top: 1px;
}

.ms-header .ms-header__nav-icon {
    background: #fff;
}

.ms-header .msc-cart-icon {
    padding-left: 40px;
}

.ms-header .ms-header__nav-icon[aria-expanded='true']::before {
    content: $msv-Cancel;
}

.ms-search__form-submitSearch {
    background-color: $arborBrown;
}

.ms-cookie-compliance__accept-button {
    background-color: $arborBrown;
    border: $arborBrown;
}

.ms-cookie-compliance__accept-button:hover,
.ms-cookie-compliance__accept-button:focus {
    background-color: $textDefaultColor;
    border: $textDefaultColor;
    color: #fff;
}

.ms-site-picker {
    align-self: auto;
    width: 170px;
    text-align: left;
    @media screen and (min-width: $msv-breakpoint-l) {
        text-align: center;
    }
}