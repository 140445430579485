// homepage styles

.main-background {
    position: absolute;
    width: 100%;
    margin-top: -100px;
    @media screen and (min-width: $screen-sm) {
        margin-top: -200px;
    }
    @media screen and (min-width: $screen-lg) {
        margin-top: -300px;
    }
    @media screen and (min-width: $screen-xxl) {
    }
    .ms-content-block[data-m-layout='full-width'] .ms-content-block__details {
        margin: 0;
        padding: 0;
    }
    picture {
        display: block;
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 432px;
        @media screen and (min-width: $screen-sm) {
            height: 675px;
        }
        @media screen and (min-width: $screen-lg) {
            height: 933px;
        }
        @media screen and (min-width: $screen-xxl) {
            height: 1440px;
            top: -200px;
        }
        img {
            display: block;
            position: absolute;
            left: 50%;
            max-width: none;
            width: 768px !important;
            margin-left: -384px;
            @media screen and (min-width: $screen-sm) {
                width: 1200px !important;
                margin-left: -600px;
            }
            @media screen and (min-width: $screen-lg) {
                width: 1660px !important;
                margin-left: -830px;
            }
            @media screen and (min-width: $screen-xxl) {
                width: 2560px !important;
                margin-left: -1280px;
            }
        }
    }
}

.hero {
    margin-top: 200px;
    @media screen and (min-width: $screen-sm) {
        margin-top: 300px;
    }
    @media screen and (min-width: $screen-xxl) {
        margin-top: 400px;
    }
    img {
        width: auto !important;
        max-width: 300px;
        @media screen and (min-width: $screen-sm) {
            max-width: 400px;
        }
        margin: 0 auto;
    }

    .ms-content-block__details {
        display: block !important;
        position: relative !important;
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 30px 0 0;
        width: auto;
        text-align: center;
        height: auto;
    }

    .ms-content-block__details::before {
        content: '';
        position: absolute;
        width: 300px;
        background: #000;
        height: 4px;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .ms-content-block__title {
        line-height: normal;
        display: block;
    }
}

// Homepage while logged in

.flex-fix {
    height: 100%;
    .ms-text-block {
        margin-top: 0;
    }
}
