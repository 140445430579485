// Content Block

.double-up {
    margin-top: 60px;
    margin-bottom: 100px;
    .ms-content-block__details {
        //border: 8px solid #000;
        padding: 10px;
    }

    .lets-do-relative {
        position: relative;
    }
    .lets-do-gray {
        background: $arborGray;
        position: relative;
        min-height: 278px;
    }
    h2 {
        margin-bottom: 25px;
    }

    .ms-content-block__cta {
        margin-top: 32px;
        a {
            padding: 12px 20px;
            min-width: 160px;
            margin: 8px;
            display: inline-block;
            transition: all 0.4s ease;
            box-sizing: border-box;
            font-family: $jost;
            font-weight: 700;
        }
        a:link,
        a:active,
        a:visited {
            color: #000;
            background: #fff;
        }
        a:hover {
            background: $arborBrown;
            color: #fff;
            text-decoration: none;
        }
    }
    .row {
        justify-content: space-between;
        .col-md-6 {
            flex: 0 0 48%;
            text-align: center;
        }
    }
    .modified-content-block-2022 {
        background: $arborGray;
        padding: 20px;
        .black-back {
            background: #000;
            position: relative;
            padding: 8px;

            .vertical {
                position: absolute;
                left: 20px;
                right: 20px;
                top: -20px;
                bottom: -20px;
                background: $arborGray;
                transition: all 0.4s ease;
            }
            .horizontal {
                position: absolute;
                left: -20px;
                right: -20px;
                top: 20px;
                bottom: 20px;
                background: $arborGray;
                transition: all 0.4s ease;
            }
        }
    }
    .modified-content-block-2022:hover {
        cursor: pointer;
        .black-back {
            .vertical {
                margin-left: 40px;
                margin-right: 40px;
            }
            .horizontal {
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
    }
}

.double-up.inside-lg {
    .row {
        justify-content: center;
        @media screen and (min-width: $screen-xs) {
            justify-content: space-around;
        }
    }
}
