// Checkout and Cart Styles

.msc-btn,
.msc-cart__empty-cart .msc-cart-line {
    font-family: $jost !important;
}

.msc-modal .msc-add-to-cart__dialog__go-to-cart {
    background-color: $arborBrown;
    border: 1px solid $arborBrown;
}

.msc-modal .msc-add-to-cart__dialog__go-to-cart:hover,
.msc-modal .msc-add-to-cart__dialog__go-to-cart:focus {
    background-color: $textDefaultColor;
    border: 1px solid $textDefaultColor;
    color: #fff;
}

.ms-cart-icon__flyout-container .msc-flyout-inner .ms-cart-icon__btn-checkout {
    background-color: $arborBrown;
    border: $arborBrown;
}

.ms-cart-icon__flyout-container .msc-flyout-inner .ms-cart-icon__btn-checkout:hover,
.ms-cart-icon__flyout-container .msc-flyout-inner .ms-cart-icon__btn-checkout:focus {
    background-color: $textDefaultColor;
    border: $textDefaultColor;
    color: #fff;
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-promo-code__apply-btn {
    font-family: $jost;
    background-color: $arborBrown;
    border: $arborBrown;
}

.msc-cart__btn-checkout:hover,
.msc-cart__btn-checkout:focus,
.msc-cart__btn-guestcheckout:hover,
.msc-cart__btn-guestcheckout:focus,
.msc-promo-code__apply-btn:hover,
.msc-promo-code__apply-btn:focus {
    background-color: $textDefaultColor;
    border: $textDefaultColor;
    color: #fff;
}

.msc-cart__btn-guestcheckout,
.msc-promo-code__apply-btn {
    font-family: $jost;
    background-color: $arborLtBrown;
    border: 1px solid $arborBrown;
}

.msc-cart__btn-guestcheckout:hover,
.msc-cart__btn-guestcheckout:focus,
.msc-promo-code__apply-btn:hover,
.msc-promo-code__apply-btn:focus {
    background-color: $textDefaultColor;
    border: $textDefaultColor;
}

.msc-cart__empty-cart .msc-cart__btn-backtoshopping {
    background-color: $arborBrown;
    border: $arborBrown;
}

.msc-cart__empty-cart .msc-cart__btn-backtoshopping:hover,
.msc-cart__empty-cart .msc-cart__btn-backtoshopping:focus {
    background-color: $textDefaultColor;
    border: $textDefaultColor;
    color: #fff;
}

.ms-checkout__guided-card-title {
    line-height: 1.4;
    span {
        display: inline-block;
    }
}

.ms-checkout__guided-card-title-step {
    width: auto;
    margin-right: 5px;
}

.msc-address-form__input,
.msc-address-form__dropdown {
    border: 1px solid #d1d1d1;
}

.ms-checkout__guided-card-body {
    padding-left: 0;
}

.ms-checkout__guided-card-title {
    font-size: unset;
}

.ms-checkout__title {
    @media screen and (min-width: $screen-md) {
        font-size: 2.5em !important;
    }
}

.msc-order-summary__items {
    font-family: $jost;
}

.ms-checkout__guided-card-btn-save {
    background-color: $arborBrown;
    border: $arborBrown;
}

.ms-checkout__guided-card-btn-save:hover,
.ms-checkout__guided-card-btn-save:focus {
    background-color: $textDefaultColor;
    border: $textDefaultColor;
    color: #fff;
}

.ms-checkout-guest-profile__input-text {
    font-family: $jost;
    border: 1px solid #d1d1d1;
}

.ms-checkout-guest-profile__input-label {
    font-family: $jost;
}

.msc-cart-line__remove-item,
.msc-cart-line__add-to-order-template,
.msc-cart-line__add-to-wishlist {
    font-family: $jost;
}

.msc-cart-line__remove-item::after,
.msc-cart-line__add-to-order-template::after,
.msc-cart-line__add-to-wishlist::after {
    content: $msv-Heart;
}

.msc-add-to-order-template-icon::after {
    content: $msv-file;
}

.msc-cart-line__remove-item::after {
    content: $msv-Cancel !important;
}

.msc-cartline-wraper {
    .msc-add-to-wishlist,
    .msc-add-to-order-template-icon {
        border: none;
    }
}

.msc-cart-line__remove-item,
.msc-add-to-wishlist,
.msc-add-to-order-template-icon {
    position: relative;
    width: auto !important;
    border: none;
    padding-right: 10px;
}

.msc-add-to-order-template-icon::after,
.msc-add-to-wishlist::after {
    color: #000;
    margin-left: 10px;
}

.msc-cart-line__product-title {
    font-family: $yanone;
    font-size: 1.3em;
}

.msc-cart-line__product-variants,
.msc-cart-line__other-charges-label,
.msc-cart-line__freight-label,
.msc-cart-line .shipping-value,
.msc-cart-line .other-charge-value {
    font-family: $jost;
}

.ms-quick-order__description {
    font-family: $jost;
    padding-right: 0;
    line-height: normal;
}

.ms-order-template__add-template-button {
    background-color: $arborBrown;
    border: $arborBrown;
}
.ms-order-template__add-template-button:hover {
    background-color: $textDefaultColor;
    border: $textDefaultColor;
}

.ms-order-template .ms-order-template-action-bar__rename-template-button {
    border: 1px solid $arborBrown;
}
.ms-order-template .ms-order-template-action-bar__rename-template-button:hover,
.ms-order-template .ms-order-template-action-bar__rename-template-button:focus {
    background-color: $arborLtBrown;
}

.ms-order-template .ms-order-template-action-bar__delete-template-button {
    border: 1px solid $arborBrown;
}
.ms-order-template .ms-order-template-action-bar__delete-template-button:hover,
.ms-order-template .ms-order-template-action-bar__delete-template-button:focus {
    background-color: $arborLtBrown;
}

.ms-order-template__table .ms-order-template-table-line__product-info-name,
.ms-order-template__table .ms-order-template-table-line__product-price {
    font-family: $jost;
}

.ms-quick-order {
    margin-top: 0;
}

.ms-quick-order__drawer {
    margin-top: 0;

    .drawer__button {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        .ms-quick-order__heading {
            line-height: normal;
            margin-left: 0;
        }
    }
}

.ms-quick-order__drawer .drawer__button[aria-expanded='false']::after {
    position: absolute;
    right: 10px;
    top: 10px;
}

.ms-quick-order__drawer .drawer__button[aria-expanded='true']::after {
    position: absolute;
    right: 10px;
    top: 10px;
}

.ms-quick-order__drawer .drawer__button[aria-expanded='true']::after {
    line-height: normal;
}

.ms-quick-order__heading {
    line-height: normal;
    margin-left: 0;
}
.ms-quick-order__description {
    margin-left: 0;
}

.ms-quick-order__drawer .drawer__button::after {
    color: #000;
}

.btn-secondary:focus,
.btn-secondary.focus {
    box-shadow: none !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
}

.ms-quick-order__drawer {
    background: $arborGray;
}

.ms-quick-order__drawer .drawer__button {
    border: none !important;
    background: none;
}

.ms-quick-order__content {
    border-top: 0;
    background-color: transparent;
}

.ms-quick-order__content-form-main-product-lable,
.ms-quick-order__content-form-main__dropdown-quantity-label,
.ms-quick-order__content-form-main__dropdown-quantity-label {
    font-family: $jost !important;
    font-size: 1.1em !important;
    line-height: normal;
    font-weight: 400;
    height: auto;
}
.ms-quick-order__content-form-main-quantity-lable {
    font-family: $jost !important;
    font-size: 1.1em !important;
    line-height: normal;
    font-weight: 400;
    height: auto;
}

.ms-quick-order__content-form-main-addtocart-button {
    background-color: $arborBrown;
    border: $arborBrown;
    margin-top: 28px;
}
.ms-quick-order__content-form-main-addtocart-button:hover,
.ms-quick-order__content-form-main-addtocart-button:focus {
    background-color: $textDefaultColor;
    border: $textDefaultColor;
}

.msc-order-summary__line-sub-total {
    padding: 10px 0 10px;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
}

.ms-quick-order__content-form-main-product {
    height: auto;
}

.msc-order-summary__heading {
    font-family: $yanone;
}

.ms-quick-order__content-form-main-quantity-lable {
    line-height: normal;
}

.ms-checkout-delivery-options__price,
.ms-checkout-delivery-options__description {
    font-family: $jost;
}
.ms-checkout-shipping-address .msc-address-detail {
    font-family: $jost;
}

.ms-checkout-delivery-options__price {
    .msc-price__actual {
        visibility: hidden;
        position: relative;
    }
    .msc-price__actual::after {
        content: 'To Be Determined';
        visibility: visible;
    }
}

.msc-cart-lines-group-wraper__bopis-heading {
    display: none;
    top: 0;
}

.ms-checkout-customer-account__btn-pay,
.ms-checkout-customer-account__btn-pay {
    background-color: $arborBrown;
    border: 1px solid $arborBrown;
}

.ms-checkout-customer-account__btn-pay:hover,
.ms-checkout-customer-account__btn-pay:focus {
    background-color: $textDefaultColor;
    border: 1px solid $textDefaultColor;
}

.msc-order-summary__line-shipping {
    .msc-order-summary__value {
        visibility: hidden;
        .msc-price__actual {
            display: none;
        }
    }
    .msc-order-summary__value::after {
        content: 'To be calculated';
        visibility: visible;
    }
}

// New Cart Line

.msc-cart-line__product-quantity .quantity .decrement,
.msc-cart-line__product-quantity .quantity .increment {
    padding: 0;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
}
.msc-cart-line__product-quantity .quantity .decrement::after,
.msc-cart-line__product-quantity .quantity .increment::after {
    font-size: 12px;
    line-height: 0.4;
}
.msc-cart-line__product-quantity .quantity .quantity-input {
    font-family: $jost;
    border-radius: 4px;
    position: relative;
    top: 2px;
}

.msc-cart-line__product-quantity .quantity .increment:hover,
.msc-cart-line__product-quantity .quantity .increment:focus {
    background-color: rgba($color: $arborBrown, $alpha: 0.5);
}
.msc-cart-line__product-quantity .quantity .decrement:hover,
.msc-cart-line__product-quantity .quantity .decrement:focus {
    background-color: rgba($color: $arborBrown, $alpha: 0.5);
}

.ms-checkout__line-items-delivery-group .msc-cart-line__content .msc-cart-line__product-price {
    display: none;
}
.msc-cart-line__product-unit-price {
    margin-top: 10px;
    font-size: 0.9em;
}

.ms-checkout__btn-place-order {
    background-color: $arborBrown;
    border: 1px solid $arborBrown;
}

.ms-checkout__btn-place-order:hover,
.ms-checkout__btn-place-order:focus {
    background-color: $textDefaultColor;
    border: 1px solid $textDefaultColor;
}

.ms-order-confirmation__btn-keep-shopping {
    background-color: $arborBrown;
    border: 1px solid $arborBrown;
}
.ms-order-confirmation__btn-keep-shopping:hover,
.ms-order-confirmation__btn-keep-shopping:focus {
    background-color: $textDefaultColor;
    border: 1px solid $textDefaultColor;
    color: #fff;
}

.ms-order-confirmation__order-summary {
    font-family: $jost;
}
.ms-order-confirmation__order-summary-line-subtotal, .ms-order-confirmation__order-summary-line-shipping, .ms-order-confirmation__order-summary-line-tax-amount {
    
    .ms-order-confirmation__order-summary-price {
        
        .msc-price__actual {
            display: none;
        }
    }
    .ms-order-confirmation__order-summary-price::before {
        content: 'To be determined';
    }
}

// hide Same as shipping address
.ms-checkout-billing-address__shipping-address-label {
    position: absolute;
    left: -9999em;
}



.ms-cart {
    .msc-add-to-wishlist, .msc-cart-line__add-to-order-template {
      border-color: transparent;
      height: auto;
      width: auto;
      background: none;
      display: block;
      margin-top: 10px;
      text-decoration: none;
    }
  
    .msc-cart-line__remove-item {
      display: block;
      margin-top: 10px;
      
    }
    .msc-cart-line__add-to-wishlist::after {
      font-family: "Font Awesome 5 Free";
      content: $msv-Heart;
      margin-left: 10px;
      border: none;
    }

    .msc-cart-line__add-to-order-template::after {
        font-family: "Font Awesome 5 Free";
        content: $msv-file;
        margin-left: 10px;
        border: none;
    }
  
    .msc-cart-line__remove-item,
    .msc-cart-line__add-to-wishlist {
      height: auto;
    }
  }




.msc-cart-line__remove-item::after, .msc-cart-line__add-to-order-template::after, .msc-cart-line__add-to-wishlist::after {
    content: $msv-Cancel;
    font-family: $msv-icon-font-family;
    font-weight: 700;
    margin-left: 5px;
}

.ms-cart .msc-cart-line__remove-item {
    text-decoration: none;
}

.lli-checkout-additional_info__order-reference-input-text, .lli-checkout-additional_info__order-notes-input-text {
    margin-bottom: 20px;
}



.react-datepicker__input-container input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    display: inline-block;
    margin-left: 10px;
}









.ms-checkout-customer-account__form-edit {
    background: #efefef;
    padding: 20px;
    margin-top: 20px;
    border-radius: 4px;
    .ms-checkout-customer-account__account-name {
        padding-top: 0;
    }
}

.ms-checkout-customer-account__credit-details-section {
    padding: 20px;
    background: #fff;
}

.ms-checkout-customer-account__account-credit-current-order-preview {
    margin-top: 0;
}

.ms-checkout-customer-account__credit-details-section {
    width: 100%;
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    margin-top: 10px;
}

.ms-checkout-customer-account__account-credit-current-order-icon::after {
    margin-right: 10px;
}

.ms-checkout-customer-account__btn-pay {
    margin-top: 20px;
    margin-left: 0;
    visibility: hidden;
    position: relative;
}

.ms-checkout-customer-account__btn-pay::after {
    background-color: rgba($color: $arborBrown, $alpha: 1);
    border: 1px solid $arborBrown;
    color: #fff;
    padding: 12px 20px;
    content: "Proceed";
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
}

.ms-checkout-customer-account__btn-pay::after:hover, 
.ms-checkout-customer-account__btn-pay::after:focus {
    background-color: rgba($color: $arborBrown, $alpha: .8);
}

.ms-checkout-customer-account__btn-pay:hover, .ms-checkout-customer-account__btn-pay:focus {
    background-color: rgba($color: $arborBrown, $alpha: .8);
    border: 1px solid $arborBrown;
}
