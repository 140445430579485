// overrides

.ms-nav {
    border: none;
    background: #fff;
    .ms-nav {
        > &__list {
            background: #fff;
        }
    }
}

// desktop navigation
.desktop-nav .ms-nav {
    display: none;
    @media screen and (min-width: $msv-breakpoint-l) {
        display: block;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item {
    margin-top: 0;
    background: #fff;
    @media screen and (min-width: $msv-breakpoint-l) {
        max-height: 75px;
    }
}

.ms-header__mobile-hamburger-menu-links {
    .ms-nav.desktop-vp {
        display: block !important;
        @media screen and (min-width: $msv-breakpoint-l) {
            display: none !important;
        }
    }

    .ms-nav__list__item__button,
    .ms-nav__list__item__link {
        //display: block;
        padding: 5px 20px;
        box-sizing: border-box;
        position: relative;
        @media screen and (min-width: $msv-breakpoint-l) {
            padding: 5px 20px;

            border-bottom: 2px solid #fff;
            height: auto;
            display: block;
        }
    }
    .ms-nav__list__item__button::after {
        position: absolute;
        right: 20px;
    }
    .ms-nav__list__item > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
        line-height: 35px;
        background-color: #f8f7f6;
    }
}

.ms-nav > .ms-nav__list .ms-nav__list {
    height: auto;
}

.ms-header__container .ms-header__collapsible-hamburger .ms-nav .ms-nav__list__item__link {
    padding: 5px 20px;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item {
    margin: 0 !important;
}

.ms-nav__list__item__button,
.ms-nav__list__item__link {
    height: 100%;
    display: flex;
    align-items: center !important;
}

.ms-header .ms-nav.desktop-vp {
    height: 100%;
}

.ms-nav__list__item {
    font-weight: 300;
    font-family: $jost;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    font-weight: 400;
    font-family: $jost;
    @media screen and (min-width: $msv-breakpoint-l) {
        padding: 0 10px;
    }
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.9em;
    background: #fff;
    @media screen and (min-width: $msv-breakpoint-l) {
        max-height: 75px;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button::after {
    transition: transform 0.3s ease;
    @media screen and (min-width: $msv-breakpoint-l) {
        transform: rotate(-90deg);
    }
    font-size: 0.8em;
    margin-top: -2px;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button[aria-expanded='true'] {
    background: #f8f7f6;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button[aria-expanded='true']::after {
    transform: rotate(90deg);
    @media screen and (min-width: $msv-breakpoint-l) {
        transform: rotate(0deg);
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list {
    background: #fff;
    //max-height: 75px;
}

.ms-nav__list__item__button,
.ms-nav__list__item__link {
    //background: #fff;
    @media screen and (min-width: $msv-breakpoint-l) {
        max-height: 75px;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover::before,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:focus::before,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover::before,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus::before {
    background-color: transparent;
}

.ms-nav > .ms-nav__list {
    background: #fff;
    @media screen and (min-width: $msv-breakpoint-l) {
        max-height: 75px;
    }
}

.ms-header .ms-header__account-info.account-mobile .drawer__button {
    color: black;
}
