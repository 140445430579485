;
@import 'bootstrap/scss/bootstrap';

// call to the fabrikam design kit
@import '@msdyn365-commerce-modules/fabrikam-design-kit/src/modules/fabrikam/styles/fabrikam.theme.scss';

$jost: 'Jost', sans-serif;
$yanone: 'Yanone Kaffeesatz', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&family=Yanone+Kaffeesatz:wght@600;700&display=swap');

body {
    font-family: $jost;
    font-size: 16px;
    line-height: 20px;
}

// primary variables
$brandPrimaryColor: #ffffff;
$brandSecondaryColor: #cccccc;
$backgrounDefaultColor: #ffffff;
$textDefaultColor: #333333;
$bodyFont: $jost;
$displayFont: $yanone;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;
$arborGray: #f5f5f5;
$arborBrown: #b36031;
$arborLtBrown: #dbcac0;

a:hover {
    color: $arborBrown;
}

:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgrounDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
}

body {
    background-color: var(--backgroundDefaultColor);
}

// imports of sass sub folders
@import 'settings/index';
@import 'tools/index';
@import 'modules/index';
