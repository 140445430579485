// this is just a grid that i have set up for displaying pages. You can choose your own grid.

// Breakpoints
$screen-xxxl: 1800px;
$screen-xxl: 1660px;
$screen-xl: 1440px;
$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 600px;
$screen-xxs: 480px;
$screen-xxxs: 360px;

$msv-breakpoint-xs: 0px;
$msv-breakpoint-s: 0px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-xl: 1440px;

$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;

$msv-container-max-width-s: 768px;
$msv-container-max-width-m: 992px;
$msv-container-max-width-l: 1440px;

#main {
    > .default-container.container {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.general-content {
    #main {
        > .default-container.container {
            padding-top: 50px;
            padding-bottom: 0;
        }
    }
}

.padding-10 {
    box-sizing: border-box;
    padding: 10px;
}

.padding-20 {
    box-sizing: border-box;
    padding: 20px;
}

.always-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.text-center {
    text-align: center;
}

.justify {
    justify-content: space-between;
}

.flex-xxs {
    display: block;
    @media screen and (min-width: $screen-xxs) {
        display: flex;
        flex-wrap: wrap;
    }
}

.flex-xs {
    display: block;
    @media screen and (min-width: $screen-xs) {
        display: flex;
        flex-wrap: wrap;
    }
}

.flex-sm {
    display: block;
    @media screen and (min-width: $screen-sm) {
        display: flex;
        flex-wrap: wrap;
    }
}

.flex-md {
    display: block;
    @media screen and (min-width: $screen-md) {
        display: flex;
    }
}

.flex-lg {
    display: block;
    @media screen and (min-width: $screen-lg) {
        display: flex;
    }
}

.flex-xl {
    display: block;
    @media screen and (min-width: $screen-xl) {
        display: flex;
    }
}

.inside-xxs {
    max-width: $screen-xxs;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

.inside-xs {
    max-width: $screen-xs;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

.inside-sm {
    max-width: $screen-sm;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

.inside-md {
    max-width: $screen-md;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

.inside-lg {
    max-width: $screen-lg;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

.inside-xl {
    max-width: $screen-xl;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

.inside-xxl {
    max-width: $screen-xxl;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

.inside-xxxl {
    max-width: $screen-xxxl;
    box-sizing: border-box;
    margin: 0 auto;
}

.always-flex {
    display: flex;
}
.flex-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.align-vertical {
    display: flex;
    align-items: center;
}
