//
.ms-business-sign-up {
    font-family: $jost;
}

.ms-business-sign-up__create-button {
    background-color: $arborBrown;
    border: 1px solid $arborBrown;
    transition: all 0.3s ease;
}

.ms-business-sign-up__create-button:hover,
.ms-business-sign-up__create-button:focus {
    background-color: $textDefaultColor;
    border: 1px solid $textDefaultColor;
    color: #fff;
    text-decoration: none;
}

.ms-business-sign-up__cancel-button {
    border: 1px solid $textDefaultColor;
}

.ms-business-sign-up__cancel-button:hover,
.ms-business-sign-up__cancel-button:focus {
    background: #a5a5a5;
}

#arborcontactform {
    margin-top: 40px;
}
