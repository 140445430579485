// Search Result Container

.ms-product-search-result__item a .msc-product__title {
    font-family: $yanone;
}

.ms-search-result-container__title .ms-search-result__collection-title-prefix,
.ms-search-result-container__title .ms-search-result__collection-title-text {
    font-family: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
}
