// footer styles

footer .ms-footer > * > :last-child {
    max-width: 100%;
}

footer .ms-footer > * > * {
    max-width: 100%;
    padding-bottom: 0;
}

footer {
    background: #fff;
    .ms-content-block__details {
        @media screen and (min-width: $screen-md) {
            text-align: center;
            > div {
                display: inline-block;
            }
        }
    }

    .ms-content-block__image {
        img {
            width: 80px;
            margin-bottom: 20px;
        }
        @media screen and (min-width: $screen-md) {
            text-align: center;
            picture {
                display: inline-block;
            }
        }
    }
    .footer-centering {
        margin-top: 20px;
        @media screen and (min-width: $screen-md) {
            margin-top: 0;
            text-align: center;
            > div {
                display: inline-block;
                text-align: left;
            }
        }
    }
}

footer .ms-footer__item {
    padding: 0;
}

footer .ms-footer__link div,
footer .ms-footer__link__text {
    line-height: normal;
    font-size: inherit;
}
